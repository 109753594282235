body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  --spacing-outer: clamp(.75rem, 0.6666666666666667rem + 1.6666666666666667vw, 1.75rem);
  --font-size--xs: clamp(.875rem, 0.6666666666666667rem + 1.6666666666666667vw, 1rem);
  --font-size--sm: clamp(1rem, 0.6666666666666667rem + 1.6666666666666667vw, 1.1rem);
  --font-size--md: clamp(1.125rem, 0.6666666666666667rem + 1.6666666666666667vw, 1.5rem);
}

* {
  box-sizing: border-box;
}

main {
  position: relative;
}

.ui-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  padding: var(--spacing-outer);
}

div, p {
  color: white;
  font-weight: 300;
  font-size: var(--font-size--md);
  line-height: 1.4;
  letter-spacing: .02em;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ui-container nav svg {
  height: 48px;
  fill: white;
}

button,
nav a {
  background: none;
  border: none;
  font-family: inherit;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  font-size: clamp(1rem, 0.6666666666666667rem + 1.6666666666666667vw, 2rem);
  letter-spacing: .04em;
  text-decoration: none;
  line-height: 1.2;
  font-weight: 500;
}

button > svg {
  fill: none;
  width: 48px;
  height: 48px;
}

button > svg path {
  fill: white;
}

.marquee {
  font-size: var(--font-size--xs);
}

button:hover,
nav a:hover {
  text-underline-offset: -3px;
  text-decoration: line-through;
}

a,
a:visited {
  color: white;
}

#play-button {
  padding: 0;
  position: absolute;
  bottom: var(--spacing-outer);
  right: var(--spacing-outer);
  font-family: monospace;
}

/* #bandcamp-link {
  position: absolute;
  bottom: var(--spacing-outer);
  left: var(--spacing-outer);
} */

@media screen and (max-width: 420px){
  /* #bandcamp-link,
  #play-button {
    top: var(--spacing-outer);
    bottom: unset;
  } */
}

.small-text, .small-text a {
  font-size: 1.125rem;
  text-transform: none;
}